<template>
  <div>
    <template v-if="showInput">
      <el-input v-model="value">
        <template slot="append">
          <el-button @click="dialogVisible=true">选择图片</el-button>
        </template>
      </el-input>
      <el-image v-if="value" :src="value | tomedia" style="max-width: 8rem;margin-top: .5rem"></el-image>
      <div v-else class="image-slot" style="max-width: 8rem;margin-top: .5rem">
        <i class="el-icon-picture-outline"></i>
      </div>
    </template>
    <el-dialog custom-class="custom-dialog" append-to-body title="选择图片" :show-close="false" :visible.sync="dialogVisible" width="60rem" @open="open" :before-close="handleClose">
      <img_body ref="imgBody" @change="chooseImg"></img_body>
    </el-dialog>
  </div>
</template>

<script>

import img_body from "@/components/y-form/y-upload/img_body";

export default {
  name: "y_upload_img",
  components:{
    img_body,
  },
  model:{
    prop:"modelVal",
    event:'change'
  },
  props:{
    full:{
      type:Boolean,
      default: false
    },
    modelVal: {
      type:String,
      default:""
    },
    showInput:{
      type:Boolean,
      default: true,
    }
  },
  watch:{
    modelVal(){
      this.value = this.modelVal;
    },
    value(e){
      this.$emit('change',e);
    }
  },
  data() {
    return {
      dialogVisible: false,
      value: this.modelVal,
    }
  },
  mounted() {

  },
  methods:{
    open(){},
    showUpload(callback){
      this.dialogVisible = true;
      this.callback = callback;
    },
    chooseImg(img){
      this.dialogVisible=false;
      if(this.full){
        this.value = this.$u.toMedia(img.attachment);
      }else {
        this.value = img.attachment;
      }
      this.callback && this.callback(this.value);
    },
    handleClose(done){
      done();
    },
  }
}
</script>
<style scoped>

</style>
<style>
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  background: #f5f7fa;
  color: #909399;
  font-size: 30px;
}
.custom-dialog > .el-dialog__body{
  padding: 0;
}
</style>